import React from "react";
import Layout from "../layout/Layout";
import FormContainer from "../layout/FormContainer";
import i18next from "i18next";
import authService from "../../services/auth.service";
import fetchService from "../../services/fetch.service";
import {LIST} from "../../consts/pages";

class Login extends React.Component {

    login = async () => {
        const {onNext} = this.props;
        await authService.initClient((accessToken) => {
            fetchService.setToken(accessToken);
            onNext(LIST);
        })
    };

    render() {
        return (
            <Layout headerTitle={i18next.t("appName")}>

                <FormContainer width="100%">

                    <div className="warning-signin">
                        <p className="sign-in-label">You need to sign in to google in order to access the
                            Application</p>
                        <div id="googleSignInBtn" className="signin-button" onClick={this.login}>
                            <img src="images/google-iconpng.png" alt="Google sign in"/>
                            <span className="buttonText">Sign In With Google</span>
                        </div>
                    </div>

                </FormContainer>

            </Layout>
        );

    }
}

export default Login;