import i18next from "i18next";

export const LANGUAGES = {
    "EN": {
        code: "en",
        flag: "images/flags/english-flag.png"
    }
};

export const initialized = () => {
    return new Promise(resolve => {
        i18next.on('initialized', function () {
            resolve();
        });
    });
};