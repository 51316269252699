import * as React from "react";
import Layout from "../layout/Layout";
import i18next from "i18next";
import FormContainer from "../layout/FormContainer";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {libraryApi} from "../../api/LibraryApi";
import {jobApi} from "../../api/JobApi";
import {LIST} from "../../consts/pages";
import PageLoader from "../layout/PageLoader";

class Creation extends React.Component {

    state = {
        sourceLibraryName: "",
        libraryName: "",
        storageAdmin: "",
        libraries: undefined,
        storageAdmins: undefined,
        error: false,
        creating: false
    };

    loadLibraries = () => libraryApi.getLibrariesName().then(response => this.setState({
        libraries: response.libraries
    })).catch(() => this.setState({error: true}));

    loadStorageAdmins = () => libraryApi.getStorageAdmins().then(response => this.setState({
        storageAdmins: response.storageAdmins
    })).catch(() => this.setState({error: true}));

    componentWillMount() {
        this.loadLibraries();
        this.loadStorageAdmins();
    }

    handleChange = name => event => this.setState({[name]: event.target.value});

    validate = () => {
        const {sourceLibraryName, libraryName, storageAdmin} = this.state;
        const {onNext} = this.props;

        this.setState({creating: true});

        jobApi.createLibrary(sourceLibraryName, libraryName, storageAdmin).then(jobId => {
            if (jobId) {
                console.log("New job id " + jobId);
                onNext(LIST);
            } else {
                this.setState({error: true, creating: false})
            }
        }).catch(() => this.setState({error: true, creating: false}));
    };

    render() {
        const {onCancel, logout, email} = this.props;
        const {sourceLibraryName, libraryName, storageAdmin, error, libraries, storageAdmins, creating} = this.state;

        return (
            <Layout headerTitle={i18next.t("appName")}
                    onCancel={onCancel}
                    canGoNext={false}
                    email={email}
                    logout={logout}>

                <PageLoader visible={(libraries === undefined || storageAdmins === undefined) || creating}/>

                <FormContainer title="Create a library" width="50%">

                    <Paper className="page-paper">

                        {error && <div className="error-msg">Error while calling the server, please contact your
                            administrator</div>}

                        <TextField
                            label="New library name"
                            style={{margin: 8}}
                            placeholder="New library name"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={libraryName}
                            disabled={error}
                            onChange={this.handleChange('libraryName')}/>

                        <TextField
                            label="Source library name"
                            style={{margin: 8}}
                            placeholder={libraries ? "Source library name" : "Loading ..."}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                list: "librariesName"
                            }}
                            disabled={error || !libraries}
                            value={sourceLibraryName}
                            onChange={this.handleChange('sourceLibraryName')}/>

                        <datalist id="librariesName">
                            {libraries && libraries.map((item, key) =>
                                <option key={key} value={item}/>
                            )}
                        </datalist>

                        <TextField
                            label="Storage admin"
                            style={{margin: 8}}
                            placeholder={storageAdmins ? "Storage admin" : "Loading ..."}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                list: "storageAdminName"
                            }}
                            value={storageAdmin}
                            disabled={error || !storageAdmins}
                            onChange={this.handleChange('storageAdmin')}/>

                        <datalist id="storageAdminName">
                            {storageAdmins && storageAdmins.map((item, key) =>
                                <option key={key} value={item}/>
                            )}
                        </datalist>

                        {sourceLibraryName && libraryName && storageAdmin && !error &&
                        <div className="create-library-button">
                            <Button variant="outlined"
                                    onClick={this.validate}
                                    disabled={creating}>
                                Create
                            </Button>
                        </div>}

                    </Paper>

                </FormContainer>

            </Layout>
        );
    }

}

export default Creation;