import React from "react";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";

const Layout = ({children, headerTitle, logout, email, onCancel, canGoNext, onNext}) => (
    <React.Fragment>
        <TopBar headerTitle={headerTitle} logout={logout} email={email}/>
        {children}
        <BottomBar onCancel={onCancel} onNext={onNext} canGoNext={canGoNext}/>
    </React.Fragment>
);

export default Layout;