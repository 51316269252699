import configuration from "../consts/configuration";
import buildJwtDecode from "./jwtDecode";

buildJwtDecode()

class AuthService {

    userEmail = undefined;
    client = undefined;

    async initClient(callback) {
        // Initialize the OAuth2 client for requesting access token
        this.client = window.google.accounts && window.google.accounts.oauth2.initTokenClient({
            client_id: process.env.REACT_APP_CLIENT_ID,
            scope: configuration.oauthScope,
            callback: (response) => {
                let accessToken = response.access_token;
                const userDomain = this.userEmail.split("@")[1]
                if (process.env.REACT_APP_DOMAIN !== userDomain && process.env.REACT_APP_WHITELISTED_DOMAIN !== userDomain) {
                    this.userEmail = undefined;
                } else {
                    callback(accessToken);
               }
            },
        });

        // Initialize GSI library
        window.google.accounts && window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_CLIENT_ID,
            callback: (response) => {
                let userObject = window.jwt_decode(response.credential);
                this.userEmail = userObject.email;

                this.client.requestAccessToken({
                    hint: userObject.email,
                    prompt: "",
                });
            },
        });

        // Display One-Tap popup
        window.google.accounts.id.prompt((notification) => {
                if (notification.isNotDisplayed() || !notification.isDisplayed()) {
                    window.google.accounts.id.renderButton(
                        document.getElementById("googleSignInBtn"),
                        {theme: "outline", size: "large"}
                    );
                }
            }
        )
    }

    refreshAccessToken() {
        if (this.userEmail && this.client) {
            this.client.requestAccessToken({
                hint: this.userEmail,
                prompt: "",
            });
        }

    }

    signOut = async () => {
        window.google.accounts.id.disableAutoSelect()
        this.userEmail = undefined
        this.client = undefined
    }

}

const authService = new AuthService();

export default authService;